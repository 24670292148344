@import "../node_modules/sass-autoprefixer/scss/prefixes.scss";


@font-face {
    font-family: 'latoregular';
    src: local('latoregular'), url(./fonts/lato-regular-webfont.eot);
    src: local('latoregular'), url(./fonts/lato-regular-webfont.eot?#iefix) format('embedded-opentype'),
         local('latoregular'), url(./fonts/lato-regular-webfont.woff2) format('woff2'),
         local('latoregular'), url(./fonts/lato-regular-webfont.woff) format('woff'),
         local('latoregular'), url(./fonts/lato-regular-webfont.ttf) format('truetype'),
         local('latoregular'), url(./fonts/lato-regular-webfont.svg#latoregular) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: local('latoregular'), url(./fonts/lato-light-webfont.eot);
    src: local('latoregular'), url(./fonts/lato-light-webfont.eot?#iefix) format('embedded-opentype'),
         local('latoregular'), url(./fonts/lato-light-webfont.woff2) format('woff2'),
         local('latoregular'), url(./fonts/lato-light-webfont.woff) format('woff'),
         local('latoregular'), url(./fonts/lato-light-webfont.ttf) format('truetype'),
         local('latoregular'), url(./fonts/lato-light-webfont.svg#latoregular) format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: local('latoregular'), url(./fonts/lato-bold-webfont.eot);
    src: local('latoregular'), url(./fonts/lato-bold-webfont.eot?#iefix) format('embedded-opentype'),
         local('latoregular'), url(./fonts/lato-bold-webfont.woff2) format('woff2'),
         local('latoregular'), url(./fonts/lato-bold-webfont.woff) format('woff'),
         local('latoregular'), url(./fonts/lato-bold-webfont.ttf) format('truetype'),
         local('latoregular'), url(./fonts/lato-bold-webfont.svg#latoregular) format('svg');
    font-weight: 700;
    font-style: normal;
}


// ===== Variables =====

// - colors:
$white: #fff;

$body-color: #333; // black
$body-color-light: #b5b5b5; // gray
$body-color-even-lighter: #b2b2b2; // lighter gray

$primary-color: #e2001a; // IKEM red

$bg-color-light: #f2f2f2; // gray
$bg-color-dark: #e1e1e1;

$border-color-light: #d8d8d8; // light gray

// - fonts:
$font-regular: "latoregular", sans-serif;

// ===== Styles =====

* {
  box-sizing: border-box;
}

.muted {
  color: $body-color-light;
}

body {
  background: $bg-color-light;
  min-width: 1200px;
}

h1, h2, h3, h4, h5, h6 {
  color: $primary-color;
  font-weight: 300;
  padding-bottom: 6px;
  border-bottom: 1px solid $primary-color;
  display: inline-block;
}

a {
  color: $primary-color;

  &:hover {
    opacity: 0.5;
  }
}

img {
  max-width: 100%;
}

input {
  padding: 10px;
}

textarea:focus, input:focus, button:focus {
  outline:1px solid $body-color;
}

input.touched.error {
  outline:1px solid $primary-color;
}

::-webkit-input-placeholder {
  color: $body-color-even-lighter;
}
::-moz-placeholder {
  color: $body-color-even-lighter;
}
:-ms-input-placeholder {
  color: $body-color-even-lighter;
}
:-moz-placeholder {
  color: $body-color-even-lighter;
}

button, input[type="submit"],
.results-content-collapsible .Collapsible.add-item .Collapsible__trigger {
  background: $primary-color;
  border: 1px solid $primary-color;
  color: $white;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  @include vp-box-shadow(0px 8px 68px 0px rgba(0, 0, 0, 0.3));

  &:hover {
    color: $primary-color;
    background: $white;
  }

  &:focus {
    outline: none;
  }

  &.inverted {
    background: $white;
    color: $primary-color;

    &:hover {
      background: $primary-color;
      color: $white;
    }
  }
}

b, bold, .bold {
  font-weight: 700;
}

.primary-color {
  color: $primary-color;
}

.small-title {
  text-decoration: none;
  font-weight: normal;
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
  top: 10px;
}

.list {
  padding-left: 0;
  li {
    list-style: none;
    margin-bottom: 10px;
    background: url(images/list-bullet.png) no-repeat;
    background-position: 0 8px;
    background-size: 8px;
    padding-left: 24px;

  }
}

button.link {
  padding: 0;
  border: none;
  font: inherit;
  color: $primary-color;
  background: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  .sk-spinner {
    color: $primary-color;
    display: inline-block;
    margin-left: 0;
  }

  @include vp-box-shadow(none);
}

.switch-field {
  padding: 30px 0 60px 0;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked + label {
      background-color: $white;
      color: $primary-color;
      box-shadow: none;

      i {
        color: $primary-color;
      }
    }
  }

  label {
    float: left;
    display: inline-block;
    width: 118px;
    background-color: $primary-color;
    color: $white;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 11px 14px;
    border: 1px solid $white;
    transition: all 0.1s ease-in-out;
    @include vp-box-shadow(0px 24px 68px 0px rgba(0, 0, 0, 0.3));

    i {
      color: $white;
    }

    &:first-of-type {
      border-radius: 40px 0 0 40px;
    }

    &:last-of-type {
      border-radius: 0 40px 40px 0;
    }

    &:hover {
        cursor: pointer;
        background: $white;
        color: $primary-color;

        i {
          color: $primary-color;
        }
    }
  }
}

.primary-color {
  color: $primary-color;
}

.bg-gray {
  background-color: $bg-color-light;
}

.fa {
  color: $body-color-light;
}

.row {
  @extend .clearfix;
}

div[class^='col-'] {
  float: left;
  padding: 0 10px;
}

.row.full {
    margin: 0 auto;
    max-width: 1200px;
}

.row.full div[class^='col-'] {
  padding: 0;
}

.col-9 {
  width: 75%;
}

.col-6 {
  width: 50%;

  &.padding-right {
    padding-right: 30px;
  }
}

.col-4 {
  width: 33.333%;
}

.col-3 {
  width: 25%;
}

.light-text-color {
  color: $body-color-light;
}

.section {
  padding: 100px 0;
  max-width: 1200px;
  margin: 0 auto;
}

.hint-box {
  font-size: 14px;
  padding: 15px;
  background: $bg-color-light;
  line-height: 140%;
  position: relative;

  h4 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    font-weight: 300;
  }

  &.upload {
    margin-top: 30px;
    margin-right: 30px;
  }

  &.micrometer {
    position: absolute;
    top: 50px;
    left: calc(50% - 100px);
    z-index: 10;
    max-width: 200px;
    text-align: left;

    &:after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $bg-color-light;
      position: absolute;
      bottom: -10px;
    }
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 15px;
    display: inline-block;
    background: url(images/close-btn.png) no-repeat;
    background-size: 100%;
    width: 15px;
    height: 15px;
  }
}

.custom-scrollbar-style {
  overflow-x: hidden;
  overflow-y: scroll;
}

.custom-scrollbar-style::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  background-color: $bg-color-light;
}
.custom-scrollbar-style::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: $primary-color;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.App {
  font-family: $font-regular;
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.inline-fullwidth-form {
  margin-bottom: 10px;

  input, button {
    float: right;
    width: 100%;
    margin-right: 20px;
    max-width: 700px;
    position: relative;
    bottom: 10px;
  }

  input[type="submit"], button {
    width: 150px;
    margin-right: 0px;
    margin-top: 0px !important;
  }

  @extend .clearfix;
}

// ===== Components =====

// - Navbar:

.navbar {
  margin: 30px auto;
  max-width: 1200px;

  nav {
    float: right;
    position: relative;
    top: 10px;

    a {
      color: $primary-color;
      padding: 20px;
      text-decoration: none;
      pointer: cursor;

      &.active {
        text-decoration: underline;
        opacity: 0.5;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .validation-links-wrap {
      float: right;
      position: relative;
      color: $body-color-light;
      bottom: 25px;

      span {
        position: absolute;
        left: 20px;
        top: 10px;
      }

      a {
        position: relative;
        bottom: 10px;
      }

      &:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 60px;
        background: $primary-color;
      }
    }
  }

  @extend .clearfix;

}

.logo-wrap {
  float: left;
  position: relative;

  span {
    position: absolute;
    top: 8px;
    left: 70px;
    min-width: 300px;
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
  }
}

.logo {
  height: 45px;
}

// - Card wrapper:

.card-wrapper {
  position: relative;
  padding: 30px;
  margin: 0 auto;
  margin-bottom: 30px;
  min-height: 600px;
  max-width: 1200px;
  background: $white;
  @include vp-box-shadow(0px 24px 98px 0px rgba(0, 0, 0, 0.3));
}

.card-wrapper.full {
  padding: 0;
}

// - Upload images section:

.uploaded-images-list {
  padding: 0 10px 0 0;
  list-style: none;
  height: 230px;
  margin-bottom: 50px;
  position: relative;

  form{
    display: inline;
  }

  .image-missing {
    border: 1px solid $primary-color; // TODO do something prettier here
  }

  li {
    background: $bg-color-light;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 14px;
    color: $body-color-light;
    position: relative;

    &.segmentation {
      background: $white;
      margin-left: 20px;
    }

    input:nth-of-type(1) {
      right: 202px;
    }

    input:nth-of-type(2) {
      right: 122px;
    }

    input[name="local.umPerPx"] {
      right: 42px;
    }

    span {
      width: 280px;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
    }

    span.status-text {
      width: 100px;
      color: $body-color-light;
    }

    input {
      padding: 2px;
      width: 62px;
      position: absolute;
      top: 4px;
      right: 46px;
      font-size: 12px;
    }

    &:hover {
      color: $primary-color;
    }

    &.selected {
      background: $primary-color;
      color: $white;
    }

    &.selected:hover {
      color: $white;
    }

  }

  a {
    float: right;
  }

  p a {
    float: unset;
  }

  li:hover, a .fa:hover {
    color: $primary-color;
  }
}

.uploader-images-buttons {
  display: flex;

  button {
    margin-right: .5rem;
  }
}

.uploaded-images-list-top-label {
  float: right;
  margin-right: 30px;
  font-size: 14px;

  &:nth-child(2), &:nth-child(3) {
    margin-right: 20px;
  }
}

.image-placeholder {
  margin-top: 20px;
}

.zoom-btn {
  position: absolute;
  top: 5px;
  right: 20px;
  i {
    color: $white;
  }
}

.max-image-size-note {
  position: relative;
  color: $body-color-light;
  top: 5px
}


// - Upload form section:

.uploader-form-section {
  padding-top: 20px;
  margin-top: 30px;
  border-top: 1px solid $border-color-light;
  padding-bottom: 20px;
  position: relative;

  input:not([type=radio]), textarea {
    float: right;
    position: relative;
    bottom: 10px;
    max-width: 130px;
    right: 40px;
    font-size: 14px;
    padding: 11px 10px;
  }

  input[type=radio] {
    margin-right: 10px;
  }

  label {
    display: block;
    height: 42px;
    margin-bottom: 10px;
    position: relative;

    &:after {
      content: "*";
      display: inline-block;
      position: absolute;
      right: 28px;
      top: 3px;
      color: $primary-color;
    }
  }

  form {
    position: relative;
  }

  h2 {
    margin: 20px 10px 40px;
  }

  h3 {
    display: block;
    border-bottom: 0;
    color: $body-color;
    margin: 0 0 30px 0;

    span {
      background: $primary-color;
      color: $white;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      margin-right: 10px;
    }
  }

  input[type="submit"] {
    min-width: 200px;
    position: relative;
    top: 15px;
  }
  
  .label-detail-wrap {
    position: relative;
  }

  .label-detail {
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 18px;
  }
}

.required-form-label {
  position: absolute;
  margin-left: -67px;
  left: 50%;
  bottom: 45px;
  color: $body-color-light;

  span {
    color: $primary-color;
  }
}

.simple-comparison-uploader-form {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;

  .submit-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  input[type=submit] {
    position: unset;
    min-width: 200px;
    margin-top: auto;
  }
}

.not-required input {
  border: 1px dashed #ccc;
}

label.not-required:after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 28px;
    top: 3px;
    color: $primary-color;
  }

// .acquisition-col {
//   color: $body-color-light;
// }

// - Results navigation collapsible:

.results-navigation-collapsible {
  position: relative;
  right: 25px;
  margin-top: -19px;
  background: $bg-color-light;

 .Collapsible {
   border-bottom: 1px solid $white;
 }

 .Collapsible__trigger {
   color: $body-color-light;
   text-align: left !important;
   cursor: pointer;
   padding: 20px;
   display: block;
   width: 100% !important;
   white-space: nowrap;
   // overflow: hidden;
   padding-left: 20px !important;

   i {
     margin-right: 4px;
   }

   &.is-open i {
     color: $white;
   }

   &.is-open {
     padding-bottom: 0;
     color: $white ;
   }

   &.is-open, &.is-open + .Collapsible__contentOuter {
     background: $primary-color;
     color: $white !important;
     font-weight: normal !important;
   }

   .fa-check-circle {
     color: $primary-color;
   }

 }

 .Collapsible__contentInner {
   padding: 10px 20px;
 }

 .size-input-label {
   margin: 10px 0;
   display: block;
   font-weight: 300;
   position: relative;
   top: 10px;
 }

 .size-input-label input {
   max-width: 60px;
   text-align: right;
   float: right;
   margin-bottom: 10px;
   position: relative;
   bottom: 10px;
 }

 nav {
   padding: 10px 20px;
   border-bottom: 1px solid $white;
   color: $body-color-light;
   background: $bg-color-light;

   a {
     font-size: 26px;
     padding: 0 2px;
     margin: 0 5px;

     &:hover i {
       color: $primary-color;
     }
   }
 }
}

.results-navigation-arrows {
  display: inline-block;
  float: right;
  position: relative;
  bottom: 5px;

  i {
    font-size: 26px;
    padding: 0 4px;
    margin-left: 4px;
  }
}

// - Results navigation details table:

.navigation-details-table {
  width: 100%;
  text-align: left;
  font-weight: 300;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
  padding: 10px 0;

  tr td:nth-child(even) {
    text-align: right;
    font-weight: 400;
  }
}

.navigation-details-table-alt {
  width: 100%;
  text-align: left;
  font-weight: 300;
  border-top: 1px solid $white;
  padding: 10px 0;
  border-collapse: collapse;

  tr td:nth-child(even) {
    text-align: right;
    font-weight: 400;
  }

  td {
    padding: 2px;
  }

  .navigation-details-table-head th {
    padding-top: .5em;
  }

  tr:last-child td {
    padding-bottom: 10px;
  }
}

tr.navigation-details-table-underline {
  td {
    padding-bottom: 10px;
  }

  & + tr {
    td {
      border-top: 1px solid $white;
      margin-top: 1em;
      padding-top: 10px;
    }
  }
}

.navigation-details-table-middle {
  width: 100%;
  font-weight: 300;
  padding: 10px 0;

  tr td:nth-child(even) {
    text-align: right;
    font-weight: 400;
  }
}

// - Results content collapsible:

.results-content-collapsible {
  padding-bottom: 81px;
  background-color: $white;
  border-left: 1px solid $white;
  min-height: 600px;
  margin: -30px;

  .Collapsible:not(:first-child) .Collapsible__trigger {
    border-top: 1px solid $white;
  }

  img {
    max-height: 310px;
  }

  .summary-btn {
    position: absolute;
    right: 30px;
    bottom: 20px;
    display: none;

    .sk-spinner {
      margin: 0;
      left: -10px;
      max-height: 12px;
    }
  }

  .pix2pix-summary-btn {
    display: none;
  }

  .Collapsible__trigger {
    display: block;
    cursor: pointer;
    background: $bg-color-light;
    color: $body-color-light;
    text-align: center;
    padding: 20px 10px;

    &:hover, &.selected {
      color: $body-color;
    }

    &.is-open {
      color: $primary-color;
      font-weight: 700;
    }
  }

  .Collapsible__contentInner {
    padding: 20px;
    padding-bottom: 100px;
  }
}

.training-checkbox-wrap label {
  padding-left: 50px;
}

.simple-comparison-navigation-details {
  .training-checkbox-wrap {
    border-top: 1px solid $white;
    padding-top: 1rem;
  }
}

.isolation-btn-list {
  list-style: none;
  padding: 0;

  li {
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;
    padding: 5px 0;
    font-weight: 300;
    color: $body-color-light;

    &.counted-islets.active {
      color: #28d007;
    }

    &.islet-id.active {
      color: #1e19ff;
    }

    &:hover {
      color: $body-color;
    }

    &.active:hover {
      opacity: 0.5;
    }
  }
}

// - Results tabs:

.results-content-tabs {
  position: relative;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: inline-block;
    color: $body-color-light;
    cursor: pointer;
    margin-right: 20px;
    padding: 5px 0;
    font-weight: 300;
    font-size: 13px;

    &:hover {
      color: $body-color;
    }

    &.react-tabs__tab--selected {
      color: $primary-color;
      border-bottom: 1px solid $primary-color;
    }
  }

  img {
    cursor: zoom-in;
  }

  &.modal {
    margin: 0;
    padding: 0;
    position: relative;

    .right {
      float: right;
      margin-right: 0;
      margin-left: 1em;
    }

    .file-name {
      color: $primary-color;
      cursor: default;

      &:hover {
        color: $white;
      }
    }
  }
}

.results-content-tabs-img-wrap {
  min-height: 320px;
}

// - Results table:

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;

  tr {
    cursor: pointer;
  }

  tr:nth-child(even) {
    background: $bg-color-light;
  }

  th {
    text-align: right;
    font-weight: 300;
    font-size: 14px;
    border-bottom: 3px solid $primary-color;
    padding-right: 10px;
    min-width: 60px;

    &:first-child {
      text-align: left;
    }
  }

  td {
    text-align: right;
    padding: 10px 10px 10px 0px;
    color: $body-color-light;
    font-weight: 300;

    &:first-child {
      max-width: 320px;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      padding-left: 10px;
      padding-right: 0px;
    }
  }

  td i {
    display: inline-block;
    margin-right: 10px;
  }

  tr.active td, tr.active td i {
    color: $primary-color;
  }

  tr.included i {
    color: $primary-color;

    &:before {
      content: "\F058";
    }
  }

  tr.excluded i {

    &:before {
      content: "\F00D";
    }
  }
}

.results-header {
  position: relative;

  .Collapsible__trigger {
    border-top: 1px solid $white;
  }
}

.results-header-edit,
.results-header-remove,
.results-header-exclude {
  position: absolute;
  top: 20px;
  left: 20px;

  i {
    color: $primary-color;
    display: inline-block;
    margin-right: 6px;
  }
}

.results-header-remove {
  left: 140px;
}

.results-header-exclude {
  left: auto;
  right: 20px;
}

// - Results add another batch:

.results-content-collapsible .uploaded-images-list {
  margin-right: 10px;
  height: 198px;
}

.results-content-collapsible .uploader-form-section h2 {
  margin-left: 0;
}

.results-content-collapsible .uploader-form-section {

  input {
    max-width: 100px;
  }

  div[class^='col-'] {
    padding-right: 10px;
  }
}

.results-content-collapsible.showing-results .Collapsible.add-item {
  .pix2pix-summary-btn {
    display: inline-block;
  }
}

.results-content-collapsible .Collapsible.add-item {
  background: $bg-color-light;
  text-align: center;
  cursor: pointer;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-left: 1px solid $white;

  .summary-btn {
    display: inline-block;
  }

  .pix2pix-summary-btns {
    position: absolute;
    right: 30px;
    bottom: 20px;

    .pix2pix-summary-btn {
      padding: 10px;
      min-width: 230px;
      position: static;
      margin-left: 1em;

      .file-size {
        font-size: 10px;
        font-weight: 600;
      }
    }
  }

  .Collapsible__trigger {
    display: inline-block;
    border-top: 1px solid $primary-color;
    background: $white;
    color: $primary-color;

    &:hover {
      background: $primary-color;
      color: $white;
    }
  }

  .required-form-label {
    display: none;
  }
}

.results-content-collapsible .Collapsible.add-item-opened {

   .Collapsible__contentInner {
    padding-bottom: 0;
  }

  .Collapsible__contentOuter {
    overflow: visible !important;
  }
}

// - Overall summary:

.download-data-btns {
  display: block;
  margin-top: 20px;
  padding-bottom: 50px;
  margin-bottom: 20px;
  border-bottom: 1px solid $border-color-light;

  button {
    display: inline-block;
    margin-right: 30px;
  }

  i {
    color: $primary-color;
    margin-right: 6px;
  }
}

.summary-file-list {
  margin-top: 20px;
  color: $body-color-light;

  ul {
    margin: 0;
  }
}

// Layered image component

.layered-image {
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

// Loading screen component
.loading-screen-wrapper {
  position: relative;
  min-height: 300px;
  height: 100%;
}

.loading-screen {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .sk-spinner {
    color: $primary-color;
  }
};

// Buttons with spinners

button {
  .sk-spinner {
    color: $white;
    display: inline-block;
    margin-left: -50px;
    max-height: 14px;
    position: relative;
    top: 2px;
  }

  &:hover .sk-spinner {
    color: $primary-color;
  }
}

// Pixel size selection

.choose-pixel-size-modal {
  position: relative;
  padding-top: 30px;
  color: #333333;

  display: grid;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);

  .image-placeholder, h2 {
    margin-top: 0;
  }

  .step {
    height: 100%;
  }

  .step-number {
    background: $primary-color;
    color: $white;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
  }

  .step-separator {
    display: inline-block;
    height: 1px;
    background: $border-color-light;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 120px;
  }

  .choose-pixel-size-canvas-wrap {
    position: relative;
    text-align: center;
    height: 100%;

    .label-detail {
      left: 0;
      right: 0;
      font-size: 12px;
      position: absolute;
      bottom: 10px;
    }

    .zoom-btn {
      right: 10px;
    }
  }
  
  .px-size-wrap {
    margin-top: 10px;
    
    .label-detail {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}

.choose-pixel-size-form {
  text-align: center;

  input[type="text"] {
    margin: 50px 10px;
  }
}

.choose-pixel-size-modal-text {
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 20px;
  line-height: 140%;
  position: relative;
  margin-right: 10px;

  .step-number {
    position: absolute;
    top: 0;
    left: 0;
  }
}

label.step2 {
  position: relative;
}

.step2 .label-detail {
  position: absolute;
  left: 215px;
  top: 35px;
  font-size: 12px;
}

.step-number.second {
  position: absolute;
  left: 10px;
  bottom: 55px;
}

.step1-wrap .konvajs-content {
  max-height: 420px;
}

.ReactModal__Content {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 24px 98px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 24px 98px 0px rgba(0, 0, 0, 0.3);
  border-radius: 0 !important;
  border: none !important;
  display: flex;
  flex-flow: column;

  .modal-inner {
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;

    .close-btn-wrapper {
      text-align: center;
      flex: 0 1 20px;
      flex-direction: row;
    }

    .modal-content {
      height: 100%;
      flex: 1 1 auto;

      button {
        margin-top: 30px;
      }
    }
  }

  .close-btn {
    float: right;
    display: inline-block;
    background: url(images/close-btn.png) no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
  }
}

.zoom-overlay {
  .ReactModal__Content {
    background: $body-color !important;
  }

  .tablist-item-right, .tablist-item-left {
    &:hover {
      color: $white;
    }
  }

  .modal-title {
    color: $white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5px;
  }

  .react-tabs__tab--selected {
    color: $white !important;
    border-bottom: 1px solid $primary-color !important;
  }

  .switch-field {
    display: inline-block;
    padding: 0;
    position: relative;
    top: 7px;
    left: 5px;

    label {
      padding: 4px 5px;
      width: 90px;
    }
  }
}

// Landing page

.landing {
  line-height: 150%;
  font-weight: 300;
  text-align: justify;

  h1, h2 {
    margin-bottom: 0;
  }
}

.full-section-wrapper.network {
  background: $white;
}

.hero-text-title {
  margin-top: 50px;
}

.hero-text-img {
  margin-top: 80px;
}

.hero-text {

  strong {
    font-size: 20px;
  }
}

.figures-col-right {
  margin-top: 32px;
}

.references-list {
  padding-left: 12px;
  font-size: 11px;
  line-height: 130%;

  li {
    margin-bottom: 10px;
  }
}

.figure-wrap {
  padding: 0 4px;
  background: $bg-color-dark;
}

.footer {
  background: $white;

  footer {
    padding-top: 20px;
    padding-bottom: 30px;

    h2 {
      margin-bottom: 0;
    }
  }

  .copyright, .authors {
    font-size: 13px;
  }
}

.footer-nav-list {
  padding: 0;
  list-style: none;
  font-size: 13px;

  li {
    margin-bottom: 10px;
  }

  a {
    color: $body-color;
  }
}

.footer-extension {
  max-width: 1200px;
  border-top: 1px solid $border-color-light;
  padding: 20px 0;
  color: $body-color-light;
  margin: 0 auto;

  .copyright {
    text-align: center;
    position: relative;
    top: 10px;
  }

  .authors {
    text-align: right;
    line-height: 16px;
    position: relative;
    top: 8px;

    a {
      color: $body-color-light;
    }
  }
}

.feedback-form {
  margin-top: 30px;
  position: relative;
  padding-bottom: 70px;

  .feedback-form-email input, textarea {
    min-width: 400px;
  }

  textarea {
    border-color: $border-color-light;
    height: 70px;
  }

  button {
    position: absolute;
    bottom: 0px;
    right: 40px;
  }
}

.section.no-bottom-padding {
  padding-bottom: 0;
}

.section.no-top-padding {
  padding-top: 0;
}

.example-slider {
  width: 100%;
  position: relative;

  img {
    // width: 20%;
    padding: 0 0.5em;
  }

  .slick-slide {
    display: inline-block;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-dots {
    list-style-type: none;
    text-align: center;
    margin: 0;
    margin-top: 10px;

    li {
      display: inline;
    }

    button {
      margin: 0 0.15em;
    }

    li.slick-active button i {
      color: #323232;
    }
  }

  .slick-dots button, button.slick-next, button.slick-prev {
    background: none;
    padding: 0;
    border: 0;
  }

  span.slick-next, span.slick-prev {
    font-size: 3em;
    -webkit-box-shadow: none;
    box-shadow: none;
    bottom: 0;
    position: relative;
  }

  span.slick-prev {
    left: -25px;
    top: 140px;
  }

  span.slick-next {
    float: right;
    right: -25px;
    bottom: 134px;
  }

  .slick-arrow button {
    background: transparent;
    border: none;
    color: transparent;
    padding: 0px;
    border-radius: 0px;
    cursor: pointer;
    -webkit-box-shadow: none;
    box-shadow: none;

    i {
      font-size: 24px;
    }
  }
}

#definitions {
  img.figure {
    margin-bottom: 1em;
  }

  table {
    font-size: 0.8em;

    thead tr {
      border-bottom: 1px solid #000;
    }
  }

  table.islet-categories {
    td:nth-child(1), th:nth-child(1) {
      text-align: left;
    }

    td:nth-child(2), th:nth-child(2) {
      text-align: center;
    }

    td:nth-child(3), th:nth-child(3) {
      text-align: right;
    }
  }
}

// Pixel-to-pixel styles

.upload-miss-pair-warning {
  color: $primary-color;
  float: right;
  position: relative;
  top: 6px;
}

.Collapsible.add-item.simple {
  min-height: 84px;
}

.navigation-details-table-head th {
  font-weight: 300;
  font-size: 12px;
}

.navigation-details-table-two-col td:nth-child(2), .navigation-details-table-two-col td:nth-child(3)  {
      font-weight: 400;
}

// zoomed image legend
.zoomed-results {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;

  .tabs {
    flex: 0 1 auto;
  }

  .content {
    height: 100%;
    flex: 1 1 auto;

    .react-tabs__tab-panel--selected {
      height: 100%;
    }

    img {
      max-width: none;
    }
  }

  .switch-field {
    left: auto;
    top: 29px;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  .image-legend {
    left: auto;
    top: 29px;
    bottom: 0;
    right: 0;
    position: absolute;

    .image-legend-content {
      padding: 0 2em;
    }

    .col-6 {
      width: 100%;
      margin-bottom: 1em;
      padding: 0;
    }

    .legend-description {
      margin-bottom: 5px;
    }

    h3, h4 {
      display: block;
      color: $white;
      font-weight: normal;
      border: none;
      margin-bottom: 0;
    }
    
    h3 {
      font-weight: bold;
      font-size: 14px;
    }
    
    h4 {
      font-size: 12px;
      font-weight: 300;
      
      &:first-of-type {
        margin-top: 10px;
      }
    }

    ul {
      margin: 0;
      padding-left: 16px;
    }
    
    .list-unstyled {
      padding-left: 0;
    }
  }
}

.legend-toggle {
  &:hover {
    color: $white !important;
  }
}

.zoomed-image {
  img {
    max-width: none;
    pointer-events: none;
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
  }

  height: 100%;
}

.layered {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.layered-content {
  display: grid;
  overflow: hidden;
}

.layered .layer {
  grid-column: 1;
  grid-row: 1;
}

.image-legend {
  color: $body-color-even-lighter;
  font-weight: 300;
  font-size: 14px;

  .legend-description {
    margin-bottom: 4px;
  }

  .legend-circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid black;

    &.color-red {
      background: red;
    }
    &.color-green {
      background: #49ff00;
    }
    &.color-white {
      background: white;
    }
    &.color-blue {
      background: blue;
    }
    &.color-cyan {
      background: cyan;
    }
    &.color-grey {
      background: grey;
    }
  }

  &.home {
    background: transparent;
    bottom: -120px;
  }
}

.legend-wrap-home {
  position: relative;
  margin-top: 70px;
}

.app-error-msg {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
  color: $primary-color;
  font-size: 18px;

  span {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -10px;
  }

  i {
    color: $primary-color;
    font-size: 24px;
    margin-right: 10px;
  }
}

.tablist-item-right {
  margin-left: 100px;
}

.tablist-item-right ~ .tablist-item-right {
  margin-left: 0;
}

ul.list-unstyled {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.modal-title {
  font-weight: bold;
}

.hide-lines {
  color: white;
  font-weight: bold;
  position: absolute;
  left: 20px;
  top: 5px;
}
